export const transformBusiness = [
  {
    type: 'accelerate',
    heading: 'Accelerate Growth',
    content:
      'It enhances your customer loyalty. How? It lets you preserve the transactions in such a way that it establishes ' +
      'assurance and transparency with your customers.',
  },
  {
    type: 'securedProcess',
    heading: 'Highly Secured Processes',
    content:
      'With our advanced algorithms, it is nearly impossible to hack on to the accounts and provides top-notch security.',
  },
  {
    type: 'rapidTransaction',
    heading: 'Rapid Transactions',
    content:
      'Along with fast transactions, it keeps your data safe and loss-free.',
  },
  {
    type: 'lessIntermediary',
    heading: 'Less Intermediary Transaction',
    content:
      "It's independent of any intermediary organization. How? It works on the model of P2P networking.",
  },
  {
    type: 'increasedroi',
    heading: 'Increased ROI',
    content:
      'It helps business creating a cleverer, improved, and dynamic way of business processes that eventually results in stimulating business growth factor.',
  },
];

export const potential = [
  {
    type: 'retailSector',
    heading: 'Retail Sector',
    content:
      'Is expanding your e-commerce venture through the distribution marketplace causing you worries? Well, worry no more. ' +
      'The blockchain helps you with secure and transparent marketplace transactions, inventory-tracking, and supply chain management.',
  },
  {
    type: 'publicSector',
    heading: 'Public Sector',
    content:
      'Enhancing the entire transactions of major fields like real-estate, government-inherited assets, etc. Blockchain is a solution. ' +
      'It supports building committed transactions, clear ecosystem hence, stimulates the realization.',
  },
  {
    type: 'healthcare',
    heading: 'Healthcare Industry',
    content:
      "Preserving client's data and medical history remains the biggest challenge in the healthcare industry. " +
      'Blockchain helps secure data retrieval and storage to handle massive and real-time transactions.',
  },
  {
    type: 'streamlined',
    heading: 'Streamlined Supply Chains',
    content:
      'Tracking materials, updating the real-time processes, tracing materials, demonstrating authenticity, and simplifying ' +
      'the entire transportation flow, are the few high-end use case of Blockchain in the supply chain.',
  },
  {
    type: 'expanding',
    heading: 'Expanding Business',
    content:
      'Blockchain creates a more enhanced and advanced network by implementing a ledger-based, cryptographically-secured, ' +
      'and block-pattern cloud platform across the chain.',
  },
];
