import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import PageHero from '@components/hero';
import Heading from '@components/pageHeading';
import { SectionHeading } from '@components/Common';
import { Layout, Container } from 'layouts';

import { transformBusiness, potential } from '@constants/blockChain';

// Assets
import headerImage from '@assets/services/blockchain/blockchain-header.jpg';

import AccelerateIcon from '@assets/services/blockchain/accelerate.svg';
import SecuredProcessIcon from '@assets/services/blockchain/secured-process.svg';
import RapidTransactionIcon from '@assets/services/blockchain/rapid-transcations.svg';
import LessIntermediaryIcon from '@assets/services/blockchain/less-intermediary.svg';
import IncreasedROIIcon from '@assets/services/blockchain/increased-roi.svg';
import RetailSectorIcon from '@assets/services/blockchain/retail-sector.svg';
import PublicSectorIcon from '@assets/services/blockchain/public-sector.svg';
import HealthcareIcon from '@assets/services/blockchain/healthcare-industry.svg';
import StreamlinedSupplyIcon from '@assets/services/blockchain/streamlined-supply.svg';
import ExpandingBusinessIcon from '@assets/services/blockchain/expanding-business.svg';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 30px;
`;

const ServiceIconElem = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: start;
  flex-flow: column;
  padding: 15px 20px;
  flex: 0 0 33.33%;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    flex: 0 0 100%;
  }
  svg {
    margin-bottom: 20px;
  }
`;

const Content = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

const BgWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary.lighter};
`;

const ListWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  max-width: 500px;
  margin: 0 auto;
  &.inline {
    max-width: none;
    display: inline-flex;
    margin: 0;
    flex: 0 0 50%;
    padding: 0 20px;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      flex: 0 0 100%;
    }
  }
`;

const ListItem = styled.div`
  font-size: 14px;
  margin: 5px;
  font-weight: 600;
  &::before {
    content: ' ';
    width: 10px;
    height: 10px;
    display: inline-flex;
    margin-right: 15px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.primary.base};
  }
`;

const ItemTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
`;

const SubHeading = styled.p`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;

const MigServices = styled.div`
  padding-top: 0;
  display: flex;
  height: fit-content;
  flex-flow: row wrap;
  align-items: start;
  justify-content: center;
`;

const BlockChainService = () => {
  const iconMap = {
    accelerate: <AccelerateIcon />,
    securedProcess: <SecuredProcessIcon />,
    rapidTransaction: <RapidTransactionIcon />,
    lessIntermediary: <LessIntermediaryIcon />,
    increasedroi: <IncreasedROIIcon />,
    retailSector: <RetailSectorIcon />,
    publicSector: <PublicSectorIcon />,
    healthcare: <HealthcareIcon />,
    streamlined: <StreamlinedSupplyIcon />,
    expanding: <ExpandingBusinessIcon />,
  };

  return (
    <Layout>
      <Helmet title={'Blockchain Services'} />
      <PageHero image={headerImage} />
      <Heading title="Blockchain Services" noHeadingIcon={true}>
        <p>
          Enter the future of finance with blockchain technology, the world’s
          most trusted technology for cryptocurrency development. Digitally
          transform the way you send, receive, and trade digital currencies and
          enjoy various benefits of blockchain.
        </p>
        <p>
          Along with blocking altering or modifying data inside these blocks,
          this technology also excludes the need for intermediary institutions
          such as financial ones, banks, and so on. With an aim to save the
          transactions more securely and dependably, Blockchain has the
          potential to change the way your business operates.
        </p>
      </Heading>
      <BgWrapper>
        <Container>
          <SectionHeading>
            How blockchain can transform your business
          </SectionHeading>
          <SubHeading>
            From backend to frontend, and beyond that, we migrate it all
          </SubHeading>
          <IconWrapper>
            {transformBusiness.map((item, id) => {
              return (
                <ServiceIconElem key={id}>
                  {iconMap[item.type]}
                  <ItemTitle>{item.heading}</ItemTitle>
                  <Content>{item.content}</Content>
                </ServiceIconElem>
              );
            })}
          </IconWrapper>
        </Container>
      </BgWrapper>
      <Container>
        <SectionHeading>Benefits of Blockchain</SectionHeading>
        <MigServices>
          <ListWrapper className="inline">
            <ListItem>Chain Management</ListItem>
            <ListItem>Decentralization</ListItem>
            <ListItem>Cryptographically Safe</ListItem>
            <ListItem>Distributed & Shared</ListItem>
            <ListItem>Quality Assurance</ListItem>
            <ListItem>Peer-To-Peer Global Transactions</ListItem>
          </ListWrapper>
          <ListWrapper className="inline">
            <ListItem>Reduced Transaction Costs</ListItem>
            <ListItem>Transparency</ListItem>
            <ListItem>User Control Networks</ListItem>
            <ListItem>Agile Transaction Settlements</ListItem>
            <ListItem>Traceable & Cost-Effective Supply</ListItem>
          </ListWrapper>
        </MigServices>
      </Container>
      <Container>
        <SectionHeading>Our Staple Services</SectionHeading>
        <SubHeading>
          We provide custom blockchain development services and enterprise-class
          implementation solutions for blockchain platforms.
        </SubHeading>
        <MigServices>
          <ListWrapper className="inline">
            <ListItem>Private Blockchain development</ListItem>
            <ListItem>Blockchain Software Expertise</ListItem>
            <ListItem>BitCoin Expertise</ListItem>
            <ListItem>Cryptocurrency exchanges</ListItem>
            <ListItem>Digital Contract Development</ListItem>
            <ListItem>Blockchain development</ListItem>
          </ListWrapper>
          <ListWrapper className="inline">
            <ListItem>Wallet</ListItem>
            <ListItem>Blockchain Banking Solutions</ListItem>
            <ListItem>Supply chain management through Blockchain</ListItem>
            <ListItem>Blockchain Mining Software</ListItem>
            <ListItem>Blockchain Smart Contract Development</ListItem>
            <ListItem>
              Blockchain and Exchange Applications Threat-free
            </ListItem>
          </ListWrapper>
        </MigServices>
      </Container>
      <Container>
        <SectionHeading>Blockchains Potential</SectionHeading>
        <IconWrapper>
          {potential.map((item, id) => {
            return (
              <ServiceIconElem key={id}>
                {iconMap[item.type]}
                <ItemTitle>{item.heading}</ItemTitle>
                <Content>{item.content}</Content>
              </ServiceIconElem>
            );
          })}
        </IconWrapper>
      </Container>
    </Layout>
  );
};

export default BlockChainService;
